
  #chooseForm img {
    /* position:absolute;
    left:0; */
    -webkit-transition: opacity 1ms ease-in-out;
    -moz-transition: opacity 1ms ease-in-out;
    -o-transition: opacity 1ms ease-in-out;
    transition: opacity 1ms ease-in-out;
  }
  
  #chooseForm img.top:hover {
    opacity:0;
  }